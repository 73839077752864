import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { Grid, GridProps, OutlineEntityCard, SectionTitle } from '@shapeable/web';
import { Topic } from '@shapeable/gesda-types';

// -------- Types -------->

export type TopicGridLayoutProps = Omit<GridProps, 'items'> & {
  items: Topic[];
  title?: string;
};

export const TopicGridLayoutDefaultProps: TopicGridLayoutProps = {
  items: [],
  title: 'Associated Emerging Topics:',
  spacing: 4,
  maxColumns: 4,
  desktopAutoColumns: 4,
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding-left: ${theme.UNIT(3)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const CardStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.section`${ContainerStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(OutlineEntityCard)`${CardStyles}`,
};

export const TopicGridLayout: React.FC<TopicGridLayoutProps> = (props) => {
  const { className, items, title, ...rest } = props;

  return (
    <My.Container className={className}>
      <My.Title>{title}</My.Title>
      <My.Grid {...rest} items={items.map(topic => {
        const description = (topic.trendIntro && topic.trendIntro.text) || (topic.intro && topic.intro.text);
        return (
          <My.Card description={description} entity={topic} />
        );
      })
      } />
    </My.Container>
  )
};

TopicGridLayout.defaultProps = TopicGridLayoutDefaultProps;