import * as React from 'react';
import { graphql } from 'gatsby';
import { TrendLayout } from '../../components/entities/trend-layout';
import { createGatsbyPageComponent } from '@shapeable/web';

export default createGatsbyPageComponent('Trend', { layout: TrendLayout });

export const trendQuery = graphql`
  query TrendQuery($id: ID!) {
    platform {
      trend(id: $id) {
        color { __typename id slug name value }
        id slug name __typename outlineNumber
        description { text }
        openGraph { 
          title description { plain }
          image { url thumbnails { large { url } } }
        }
        banner {
          id
          thumbnail { url } 
          image { url } 
        }
        topics {
          id name outlineNumber slug __typename 
          # trendIntro { text }
          intro { text }
          color { id name value } 
          banner {
            id
            thumbnail { url static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } } 
            image { url } 
          }
        }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber color { value }
        }
        connectedPages {
          id name slug path type { name }
        }
        pages {
          __typename id name path slug title subtitle pretitle 
          openGraph { description { text } image { url } }
          intro { text }
          orderNumber
          type { id name slug }
          banner {
            name slug alternateText
            thumbnail { 
              id url width height static { childImageSharp { gatsbyImageData(placeholder: DOMINANT_COLOR) } } 
            }
          }
        }
      }
    }
  }
`;
