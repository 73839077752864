import React from 'react';
import { Classable, HasChildren, Trend } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { ImageAsset, MarkdownContent, OutlineNumber, ResponsiveContainer, SectionTitle, useFullscreen } from '@shapeable/web';
import { RadarChart } from '../elements/radar-chart';

// -------- Types -------->

export type TrendBannerLayoutProps = Classable & HasChildren & { 
  entity: Trend;
  label?: string;
};

export const TrendBannerLayoutDefaultProps: Omit<TrendBannerLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {
  _color?: string;
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    position: relative;
    background: ${theme.COLOR('text')};
    display: flex;
    flex-direction: column;
    ${({ _color }: ContainerProps) => css`
      background: ${_color};
    `}
  `,
  tablet: css`
    flex-direction: row;
  `
});

const BodyStyles = breakpoints({
  base: css`
    position: relative;
    flex-direction: column;
    display: flex;
  `,
  tablet: css`
    flex-direction: row;
    height: 100%;
  `
});


const InfoStyles = breakpoints({
  base: css`
    display: flex;
    width: 100%;
    flex-shrink: 0;
    flex-direction: column;
    padding: ${theme.UNIT(3)} ${theme.UNIT(0)};
  `,
  tablet: css`
    width: 45%;
  `,
  desktop: css`
    padding: ${theme.UNIT(13)} ${theme.UNIT(4)} ${theme.UNIT(4)} ${theme.UNIT(8)};
  `,
});

const TitleStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-bottom: 0;
    padding-left: ${theme.UNIT(2)};
    padding-bottom: ${theme.UNIT(3)};
    border-bottom: 1px solid ${theme.COLOR('light')};
  `,
  desktop: css`
    padding-left: 0;
  `,
});

const HeadingStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: ${theme.UNIT(4)};
    line-height: 1.0em;
    padding-left: ${theme.UNIT(2)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(8)};
    padding-left: 0;
  `,
});

const OutlineNumberStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(3)};
  `,
});

const NameStyles = breakpoints({
  base: css`
    font-weight: 500;
    font-family: ${theme.FONT('serif')};
    font-size: ${theme.FONT_SIZE(24)};
    line-height: 1.1em;
    color: ${theme.COLOR('light')};
    padding-right: ${theme.UNIT(12)};
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(28)};
    padding-right: 0;
  `,
  desktop: css`
    font-size: ${theme.FONT_SIZE(36)};
  `
  
});


const BackgroundImageStyles = breakpoints({
  base: css`
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('light')};
    margin-left: ${theme.UNIT(3)};
    padding-right: ${theme.UNIT(4)};
    line-height: 1.5em;
    font-family: ${theme.FONT('serif')};
    font-size: ${theme.FONT_SIZE(16)};
    font-weight: 300;
  `,
  tablet: css`
    margin-top: ${theme.UNIT(4)};
    font-size: ${theme.FONT_SIZE(16)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(6)};
    margin-left: ${theme.UNIT(24 + 4)};
    font-size: ${theme.FONT_SIZE(18)};
  `,
  desktopLarge: css`
    margin-top: ${theme.UNIT(10)};
    font-size: ${theme.FONT_SIZE(24)};

    &.is-fullscreen {
      font-size: ${theme.FONT_SIZE(22)};
    }
  `,

});

const RadarChartStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)};
    align-self: flex-start;
    width: 100%;
    box-sizing: border-box;
    height: auto;
    flex-grow: 1;
    margin-top: ${theme.UNIT(2)};
  `,
  tablet: css`
    margin-top: ${theme.UNIT(4)};
    padding: 0;
    margin-right: ${theme.UNIT(4)};
  `,
  desktop: css`
    margin-top: ${theme.UNIT(9)};
    margin-right: ${theme.UNIT(6)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled(ResponsiveContainer)<ContainerProps>`${ContainerStyles}`,
    BackgroundImage: styled(ImageAsset)`${BackgroundImageStyles}`,

    Body: styled.div`${BodyStyles}`,

      Info: styled.div`${InfoStyles}`,
        Title: styled(SectionTitle)`${TitleStyles}`,

        Heading: styled.h1`${HeadingStyles}`,
          OutlineNumber: styled(OutlineNumber)`${OutlineNumberStyles}`,
          Name: styled.span`${NameStyles}`,
        
        Description: styled(MarkdownContent)`${DescriptionStyles}`,
    
      RadarChart: styled(RadarChart)`${RadarChartStyles}`,
};

export const TrendBannerLayout: React.FC<TrendBannerLayoutProps> = (props) => {
  const { className, entity } = props;

  const { banner, outlineNumber, name, color, description } = entity;

  const aspectRatio = {
    base: 0,
    tablet: 3840 / 2178,
  };

  const fullscreen = useFullscreen();

  const label = process.env.TREND_BANNER_LABEL || 'Trend:';

  const hasBanner = banner && banner.image;
  const hasDescription = description && !!description.text;

  return (
    <My.Container alignItems="flex-start" justifyContent="flex-start" aspectRatio={aspectRatio} _color={color && color.value} className={className}>
      <My.Body>
        <My.Info>
          <My.Title>{label}</My.Title>
          <My.Heading>
            <My.OutlineNumber color={color} size="bannerLarge">{outlineNumber}</My.OutlineNumber>
            <My.Name color={color && color.value}>{name}</My.Name>
          </My.Heading>
          {
            hasDescription && 
            <My.Description className={fullscreen.className} text={description.text} />
          }
        </My.Info>

        <My.RadarChart activeTrend={outlineNumber} />
      </My.Body>

    </My.Container>
  )
};

TrendBannerLayout.defaultProps = TrendBannerLayoutDefaultProps;