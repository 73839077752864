import React from 'react';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { coalesce, EntityCard, Grid, GridProps, SectionTitle } from '@shapeable/web';
import { Page } from '@shapeable/types';
import { get } from 'lodash';

// -------- Types -------->

export type PageGridLayoutProps = Omit<GridProps, 'items'> & {
  items: Page[];
  title?: string;
};

export const PageGridLayoutDefaultProps: PageGridLayoutProps = {
  items: [],
  title: 'Related Content:',
  spacing: 4,
  maxColumns: 3,
  minColumns: 3,
  desktopAutoColumns: 3,
};

// -------- Child Component Props -------->

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`

  `,
});

const TitleStyles = breakpoints({
  base: css`
    padding-left: ${theme.UNIT(3)};
  `,
});

const GridStyles = breakpoints({
  base: css`
    
  `,
});

const CardStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(3)};
  `,
});


// -------- Components -------->

const My = {
  Container: styled.section`${ContainerStyles}`,
    Title: styled(SectionTitle)`${TitleStyles}`,
    Grid: styled(Grid)`${GridStyles}`,
      Card: styled(EntityCard)`${CardStyles}`,
};

export const PageGridLayout: React.FC<PageGridLayoutProps> = (props) => {
  const { className, items, title, ...rest } = props;
  
  return (
    <My.Container className={className}>
      <My.Title>{title}</My.Title>
      <My.Grid {...rest} items={items.map(page => {
        return (
          <My.Card label={get(page, 'type.name', 'Page')} entity={page} /> /* description={coalesce(page, ['openGraph.description.text', 'intro.text'])} */
        );
      })
      } />
    </My.Container>
  )
};

PageGridLayout.defaultProps = PageGridLayoutDefaultProps;