import * as React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { Helmet } from 'react-helmet';
import { Trend } from '@shapeable/gesda-types';
import { TrendLayoutBase as Layout } from './trend-layout-base';
import { TrendBannerLayout } from './trend-banner-layout';

// -------- Types -------->

export type TrendLayoutProps = HasChildren & {
  entity: Trend;
}

// -------- Components -------->

const ContainerStyles = breakpoints({
  base: css`
`,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const My = {
  Container: styled(Layout)`${ContainerStyles}`,
    Banner: styled(TrendBannerLayout)`${BannerStyles}`,
};

export const TrendLayout: React.FC<TrendLayoutProps> = (props) => {
  const { entity } = props;
  return (
    <My.Container 
      entity={entity}
      banner={<My.Banner entity={entity} />} 
    />
  );
};


