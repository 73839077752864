import * as React from 'react';
import { Classable, HasChildren, Trend } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { Helmet } from 'react-helmet';
import { PageGridLayout } from './page-grid-layout';
import { TopicGridLayout } from './topic-grid-layout';
import { PageBannerLayout } from '@shapeable/web';

import { sortBy } from 'lodash';


// -------- Types -------->


export type TrendLayoutBaseProps = HasChildren & {
  entity: Trend;
  banner?: React.ReactNode;
}

// -------- Components -------->

const ContainerStyles = breakpoints({
  base: css`

`,
});


const HeaderStyles = breakpoints({
  base: css`
  `,
});

const BannerStyles = breakpoints({
  base: css`
    
  `,
});

const BodyStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} 0;
  `,
  tablet: css`
    padding: 0;
  `
});

const TopicsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
});

const PagesStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(8)};
  `,
});



const My = {
  Container: styled.div`${ContainerStyles}`,

  Header: styled.header`${HeaderStyles}`,
    Banner: styled(PageBannerLayout)`${BannerStyles}`,
  
  Body: styled.div`${BodyStyles}`,
    Topics: styled(TopicGridLayout)`${TopicsStyles}`,
    Pages: styled(PageGridLayout)`${PagesStyles}`,
};

export const TrendLayoutBase: React.FC<TrendLayoutBaseProps> = (props) => {
  
  const { entity } = props;
  const { topics = [], pages = [], color } = entity;

  const hasTopics = !!topics.length;
  const hasPages = !!pages.length;

  const banner = props.banner || <My.Banner showBackground={false} entity={entity} />;

  return (
    <My.Container>
      {
        color && 
        <Helmet>
          <meta name="theme-color" content={color && color.value} />
        </Helmet>
      }

      <My.Header>
        {banner}
      </My.Header>

      <My.Body>
        {
          hasTopics && 
          <My.Topics items={sortBy(topics, 'outlineNumber')} />
        }
        {
          hasPages && 
          <My.Pages desktopMinColumns={3} tabletMinColumns={3} items={pages} />
        }
      </My.Body>

    </My.Container>
  );
};


